<!-- 授信账单 -->
<template>
	<div :class="themeClass" class="myPointsContent">
		<div class="margin-t-20">
			<div class="flex-row-align-center">
				<div class="billtext flex-row font-size18">授信账单</div>
				<!-- <div class="flex-row-align-center date">
					<el-date-picker v-model="value" type="daterange" range-separator="至" :start-placeholder="defaultTime" :end-placeholder="defaultTime"
						 class="font-size13 margin-l-10 chooseDate" value-format="yyyy-MM-dd" :clearable="false">
						</el-date-picker>
				</div>
				<div class="allOrder-btn">
				  <div type="primary" class="background-color-theme zad">查询</div>
				  <div type="primary" class="background-color-theme ad">execl表格导出</div>
				</div> -->
			</div>
			<div class="flex-row-align-center">
        <div class="choose-date">
          <!-- 新时间组件 -->
          <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
        </div>
				<!-- <span :class="activeName=='yearMonth'?'activeClass':''" style="width: 50px;">
					年月
				</span>
				<el-select  v-model="balance" placeholder="请选择年月" style="width: 140px;margin-right:10px;" @change="monthChange">
					<el-option v-for="(item,index) in balanceOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<div class="tabs">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane class="pointer-color-theme" label="昨天" name="yesterday"></el-tab-pane>
						<el-tab-pane class="pointer-color-theme" label="今天" name="today"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="inputBor margin-r-10">
					<el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期">
					</el-date-picker>
					<span>至</span>
					<el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期">
					</el-date-picker>
				</div> -->
				<div class="allOrder-btn">
					<div class="background-color-theme pointer zad" @click="aaa()">查询</div>
					<div type="primary" class="background-color-theme ad pointer" @click="ExportEXcel()">Execl导出</div>
				</div>
			</div>
			<!-- tab切换 -->
			<div class="tabTitleRow pointer">
				<div v-for="(item,index) in tabData" :key="index" :class="item.checked?'checkTitleSty':''" @click="selecttab(index)">
					{{item.title}}
				</div>
			</div>
			<!-- 统计列表 -->
			<div v-if="listShow == 0" >
				<div class="tabOne" v-for="(item,index) of billDetailsData">
					<img src="../../assets/u249.png" alt="">
					<p class="tabOne-Title">{{item.fShopName}}</p>
					<div class="flex-row aaa">
						<div>
							<p class="tabone-num">{{item.fLastLimitMoney}}</p>
							<p class="tabone-con">申请额度</p>
						</div>
						<div>
							<p class="tabone-num">{{item.fUsedMoney}}</p>
							<p class="tabone-con">已用额度</p>
						</div>
						<div>
							<p class="tabone-num">{{item.fAddData}}</p>
							<p class="tabone-con">还款金额</p>
						</div>
						<div>
							<p class="tabone-num">{{item.fEnableUseMoney}}</p>
							<p class="tabone-con">可用额度</p>
						</div>
						<div>
							<p class="tabone-num1">{{item.fNeedRepaymentDate}}</p>
							<p class="tabone-con1">最后还款日</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 账单报表 -->
			<div v-else v-loading="tableLoading">
				<div class="tabTwo hide-text">
					<el-table :data="tableData" border style="width: 100%;">
						<el-table-column prop="fShopName" label="店铺" width="180"> </el-table-column>
						<el-table-column prop="fBalanceID" label="单据号码" width="180"> </el-table-column>
						<el-table-column prop="fBusinessTime" label="日期"> </el-table-column>
						<el-table-column prop="fChangeCapital" label="金额"> </el-table-column>
						<el-table-column prop="fRemark" label="备注"> </el-table-column>
					</el-table>
				</div>
				<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
					<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
					 :pagesize="pagesize" :total="total"></pagination>
				</div>
			</div>
		</div>
		<div class="flex-row-center-center" v-if="billDetailsData.length == 0&&listShow == 0" v-loading="listLoading">
			<svg-icon icon-class="NoRightsAndInterests" />
		</div>
	</div>
</template>
<script>
	import pagination from '@/components/pagination/pagination.vue';
  import Timequery from "@/components/Timequery.vue"
	import {
		mapGetters
	} from "vuex";
	export default {
		components: {
			pagination,
      Timequery
		},
		data() {
			return {
				listLoading:false,  
				tableLoading:false,  
				balance:'',
				balanceOptions: [],
				total: 0, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				activeName: "today",
				// searchDate: [new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 7)), new Date()],
				searchDate: [new Date(), new Date()],
				value: "",
				listShow: 0,
				defaultTime: '', //时间默认值
				maxResultCount: 10,
				tableData: [],
				columnList:[],
				tabData: [{
						title: '统计报表',
						type: 1,
						checked: true,
					},
					{
						title: '账单列表',
						type: 2,
						checked: false
					},
				],
				tablebles: [{
						label: "店铺",
						width: "276",
						prop: "fShopName",
						align: "center",
						fixed: true
					},
					{
						label: "单据号码",
						width: "100",
						prop: "fBalanceID",
						align: "center",
						fixed: true
					},
					{
						label: "日期",
						width: "100",
						prop: "fBusinessTime",
						align: "center",
						fixed: true
					},
					{
						label: "金额",
						width: "100",
						prop: "fChangeCapital",
						align: "center",
						fixed: true
					},
					{
						label: "备注",
						width: "100",
						prop: "fRemark",
						align: "center",
						fixed: true
					}
				],
				billDetailsData: [],
			};
		},
		created() {
			this.getdayValue()
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			// this.pointBillGetList();
			this.acquireDate();
		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.searchDate[0] = val[0]
			  this.searchDate[1] = val[1]
        this.aaa()
      },
			//时间更改
			// monthChange(e){
			// 	this.activeName = "yearMonth"
			// 	this.searchDate[0] = this.getMonthFirst(e)
			// 	this.searchDate[1] = this.getMonthLast(e)
			// 	this.aaa()
			// },
			//格式化时间
			getDateNoDay(time) {
			  if (time == null || time == '') {
			    return '';
			  }
			  let date = new Date(time);
			  let year = date.getFullYear(); //年
			  let month = date.getMonth() + 1; //月
			  let day = date.getDate(); //日
			  month = month > 9 ? month : '0' + month;
			  day = day > 9 ? day : '0' + day;
			  return `${year}年${month}月`;
			},
			//获取某个月的第一天
			getMonthFirst(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth(), 1)
			 	return enddate
			},
			//获取某个月的最后一天
			getMonthLast(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth() + 1, 0)
			 	return enddate
			},
			acquireDate(){
				let allData = []
				let start = new Date();
				for(let i=0;i<12;i++){
					if(i==0){
						allData.push({
							value: new Date(),
							label: this.getDateNoDay(new Date())
						})
					}else if(i==1){
						allData.push({
							value:new Date()- 3600 * 1000 * 24 * 30,
							label: this.getDateNoDay(new Date()- 3600 * 1000 * 24 * 30),
						})
					}else if(i==2){
						allData.push({
							value:new Date()- 3600 * 1000 * 24 * 60,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 60),
						})
					}else if(i==3){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 90,
							label:  this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 90),
						})
					}else  if(i==4){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 120,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 120),
						})
					}else if(i==5){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 150,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 150),
						})
					}else if(i==6){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 180,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 180),
						})
					}else if(i==7){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 210,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 210),
						})
					}else if(i==8){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 240,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 240),
						})
					}else if(i==9){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 270,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 270),
						})
					}else if(i==10){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 300,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 300),
						})
					}else if(i==11){
						allData.push({
							value: new Date()- 3600 * 1000 * 24 * 330,
							label: this.getDateNoDay(new Date() - 3600 * 1000 * 24 * 330),
						})
					}
				}
				this.balanceOptions = JSON.parse(JSON.stringify(allData))
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.pointBillGetList1();
			},
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', '']
				}
				const start = new Date();
				const end = new Date();
				this.balance = "";
				switch (this.activeName) {
					case "all":
						this.searchDate[0] = ''
						this.searchDate[1] = ''
						break;
					case "today":
						this.searchDate[0] = start.setTime(start.getTime());
						this.searchDate[1] = end
						break;
					case "yesterday":
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24))
						this.searchDate[1] = this.searchDate[0]
						break;
					case "oneMonth":
						this.searchDate[0] = new Date().setDate(1)
						this.searchDate[1] = end
						break;
				}
				this.currentPage = 1;
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				}
			},
			//获取当前时间
			getdayValue() {
				let a = new Date()
				this.defaultTime = this.getDate(a)
			},
			//tab点击事件
			selecttab(e) {
				console.log(e, '111111');
				for (let item of this.tabData) {
					item.checked = false;
				}
				this.tabData[e].checked = true;
				this.listShow = e;
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				}
			},
			aaa() {
				if (this.listShow == 1) {
					this.pointBillGetList1()
				} else if (this.listShow == 0) {
					this.pointBillGetList()
				}
			},
			//获取日期的值
			bindChange(e) {
				console.log(e, "e")
				this.value = e;
				// // console.log('gundong',e)
				this.beginTime = this.getDate(this.value[0]);
				this.endTime = this.getDate(this.value[1]);
			},
			//获取统计列表
			pointBillGetList() {
				this.listLoading=true;
        this.billDetailsData = []
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-credit-limit/my-credit-limit", {
						filter: "",
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.maxResultCount,
						// skipCount: (this.page - 1) * this.maxResultCount,
						sorting: "",
					}
				).then(
					(result) => {
						console.log(result, "result5555555")
						this.billDetailsData = result.obj;
						for (let item of result.obj) {
							item.fNeedRepaymentDate = this.getTime(item.fNeedRepaymentDate).substring(0, 10);
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fEndTime = this.getDate(item.fEndTime);
							item.fBeginTime = this.getDate(item.fBeginTime);
						}
						this.listLoading=false;
					},
					(error) => {
						//   alert(JSON.stringify(error));
						this.listLoading=false;
					}
				);
			},
			// 获取账单列表   
			pointBillGetList1() {
				this.tableLoading=true;
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-credit-limit-detail/get-credit-limit-by-customerid", {
						filter: "",
						beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						maxResultCount: this.pagesize,
						skipCount: (this.currentPage - 1) * this.pagesize,
						sorting: "",
					}
				).then(
					(result) => {
						console.log(result, "result444444444")
						// this.billDetailsData = result.obj.items;
						this.tableData = result.obj.items;
						for (let item of result.obj.items) {
							if(item.fBusinessTime !=null){
								item.fBusinessTime = this.getTime(item.fBusinessTime);
							}
							item.fModifyTime = this.getTime(item.fModifyTime);
							item.fEndTime = this.getDate(item.fEndTime);
							item.fBeginTime = this.getDate(item.fBeginTime);
						}
						this.total = result.obj.totalCount;
						this.tableLoading=false;
					},
					(error) => {
						//   alert(JSON.stringify(error));
						this.tableLoading=false;
					}
				);
			},
			// EXcel导出
			ExportEXcel() {
				this.downloadLoading = true;
				console.log('列表数据', this.tablebles);
				this.columnList = [];
				for (var i = 0; i < this.tablebles.length; i++) {
					this.columnList.push({
						Key: this.tablebles[i].prop,
						Value: this.tablebles[i].label
					});
				}
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				if (this.tableData.length == 0) {
					return this.$message('导出数据不能为空！');
				}
				this.requestUrl = 'api/mall/ebbalance/customer-credit-limit-detail/down-limit-detail-by-customerid';
				this.ApiRequestPostNOMess(this.requestUrl, {
					model: this.columnList,
					beginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					endTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					filter: ''
				}).then(
					result => {
						console.log('获取EXcel导出数据', result);
						window.open(result.obj);
						this.downloadLoading = false;
					},
					rej => {
						this.downloadLoading = false;
					}
				);
			}
		},
	};
</script>
<style scoped lang="scss">
  .choose-date {
    width: 406px;
    display: flex;
    align-items: center;
  }
	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		display: none;
	}

	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.inputBor {
		width: 280px;
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		line-height: 50px;
	}

	.tabone-num {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #6C40BF;
		text-align: center;
	}

	.tabone-con {
		font-size: 14px;
		color: #6C40BF;
		border-bottom: none;
	}

	.tabone-num1 {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #333333;
	}

	.tabone-con1 {
		font-size: 14px;
		color: #333333;
		text-align: center;
		border-bottom: none;
	}

	.date {
		position: relative;
		top: 2px;
		left: 285px;
	}

	.myPointsContent {
		width: 921px;
		min-height: 485px;
		background-color: #FFFFFF;
		// padding:0px 13px;
	}

	.allOrder-btn {
		display: flex;
		// position: relative;
		// left: 295px;
	}

	.allOrder-btn div:nth-child(1) {
		width: 75px;
		height: 32px;
		margin-right: 5px;
		text-align: center;
		line-height: 32px;
	}

	.allOrder-btn div:nth-child(2) {
		width: 110px;
		height: 32px;
		text-align: center;
		line-height: 32px;
	}

	.block {
		margin-left: 260px;
		margin-right: 10px;
	}

	::v-deep .el-table__row {
		height: 38px;
	}

	.red {
		color: #ff0000;
	}

	.black {
		color: #000;
	}

	/* tab切换标题 */
	.tabTitleRow {
		height: 40px;
		background-color: #F8F9FE;
		border: 1px solid #eee;
		margin-top: 17px;
		box-sizing: border-box;
		margin-bottom: 17px;
	}

	::v-deep .el-table .cell {
		white-space: nowrap;
	}

	::v-deep .el-table thead {
		font-size: 13px;
		color: #000000;
		font-weight: 500;
		text-align: center;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		height: 38px;
		padding: 0px 0px;
		text-align: center;
	}

	::v-deep .el-table td,
	::v-deep .el-table th {
		text-align: center;
	}

	::v-deep .is-leaf {
		border-right: 0px solid transparent;
		background-color: #F2F2F2;
	}

	.tabTitleRow div {
		width: 108px;
		display: inline-block;
		height: 40px;
		line-height: 40px;
		color: #666;
		text-align: center;
		box-sizing: border-box;
	}

	.tabTitleRow div:first-child {
		border-left: none !important;
	}

	.tabTitleRow .checkTitleSty {
		@include themify($themes) {
			color: themed("themes_color");
		}

		font-weight: bold;
		background-color: #fff;
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}

	.line-height39 {
		line-height: 39px;
	}

	.billtext {
		line-height: 36px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.aaa {
		width: 800px;
		justify-content: space-evenly;
		margin-top: 20px;
		border-bottom: none;
	}

	.tabOne {
		width: 100%;
		height: 135px;
		background: #F8F5FC;
		border-radius: 2px;
		border: 1px solid #6C40BF;
		margin-top: 10px;
		position: relative;
	}

	.tabOne-Title {
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 15px;
		color: #6C40BF;
		margin: 10px 0px 10px 10px;
	}

	.tabOne img {
		width: 180px;
		height: 119px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.topbgc {
		// width: 917px;
		height: 35px;
		background: #f8f9fe;
		padding: 0 15px;
		margin: 11px auto;
	}

	::v-deep.el-input--suffix .el-input__inner {
		line-height: 32px !important;
		height: 32px !important;
	}

	::v-deep .el-input__icon {
		line-height: 32px !important;
	}

	.billsitems {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #f2f2f2;
	}

	.allOrder-btn .zad {
		/* position: absolute;
		left: 1095px; */
	}

	.allOrder-btn .ad {
		/* position: absolute;
		left: 1175px; */
	}
	.activeClass{
		width: 50px;
		height: 32px;
		background-color: #0173FE;
		color:white;
		line-height: 32px;
		text-align: center;
	}
  /deep/ .el-loading-mask {
    right: -6px;
    bottom: -6px;
  }
</style>
